const IconReferral = ({ color }) => {
  return (
    <svg width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.8406 16.6387C21.6055 15.0117 20.9089 13.0345 19.2235 12.3042C19.4061 8.66392 17.2763 5.50719 14.1683 4.19001C14.243 2.33605 12.7692 0.85 11.0001 0.85C9.23113 0.85 7.75735 2.3373 7.83198 4.18998C4.69956 5.51654 2.5933 8.69184 2.77557 12.306C1.09625 13.0342 0.39223 15.0093 1.15972 16.6388C1.92215 18.2579 3.87741 18.9658 5.50476 18.0961C8.64132 20.9493 13.3564 20.9519 16.4956 18.0961C18.1215 18.9645 20.0768 18.2606 20.8406 16.6387L20.8406 16.6387ZM8.04307 5.23115C8.50522 6.42515 9.65359 7.27642 10.9975 7.27642C12.3411 7.27642 13.4892 6.42561 13.9516 5.23098C14.7496 5.59682 15.4796 6.10977 16.1011 6.74101L10.9975 11.9104L5.89391 6.74093C6.51423 6.1099 7.2451 5.597 8.04307 5.23115ZM10.4799 12.8655V19.1737C8.94634 19.0641 7.50614 18.4672 6.33486 17.4567C7.20175 16.5249 7.4725 15.1147 6.88901 13.8743L6.889 13.8742C6.31457 12.6534 5.07348 11.9565 3.7901 12.0536C3.75251 10.4363 4.25444 8.84327 5.2173 7.53491L10.4799 12.8655ZM3.10338 13.2995L3.10351 13.2994C4.1692 12.7844 5.44551 13.2406 5.95536 14.3241L5.95539 14.3242C6.46565 15.4073 6.01208 16.7005 4.94526 17.2161C3.88087 17.7299 2.60194 17.271 2.09468 16.1915L2.0626 16.1233C1.60222 15.0544 2.05902 13.8031 3.10338 13.2995ZM15.1061 13.8742C14.5225 15.1136 14.7922 16.5238 15.6603 17.458C14.489 18.4683 13.0478 19.0641 11.5151 19.1749V12.8655L16.7777 7.535C17.7385 8.84232 18.2425 10.4354 18.205 12.0537C16.947 11.9596 15.6919 12.6317 15.1061 13.8741L15.1061 13.8742ZM17.0525 17.2148L17.0524 17.2148C15.9845 16.6992 15.5319 15.4062 16.0423 14.3215L16.0423 14.3215C16.5498 13.2429 17.8236 12.7806 18.8928 13.2981L18.8929 13.2981C19.9597 13.8137 20.4134 15.1068 19.9031 16.1914C19.3945 17.271 18.1156 17.7286 17.0525 17.2148ZM10.9988 1.89336C12.1774 1.89336 13.1391 2.86487 13.1391 4.06189C13.1391 5.25902 12.1773 6.23174 10.9988 6.23174C9.82038 6.23174 8.85856 5.26029 8.85856 4.06189C8.85856 2.86361 9.82027 1.89336 10.9988 1.89336Z"
        fill={color} stroke={color} strokeWidth="0.3" />
    </svg>

  );
};

export default IconReferral;
