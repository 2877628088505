import React from 'react';
import { Modal } from '@mui/material';
import CommonHeader from '../commonHeader/CommonHeader';
import Footer from 'component/mobile/app/layouts/Footer/Footer';

import styles from './CommonModal.module.css';

const CommonModal = (props) => {
  const { open, handleCloseModal, children, hasLogo, hasClose, hasBack, hasFooter = true } = props;

  return (
    <Modal open={open}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <CommonHeader
            handleClose={handleCloseModal}
            hasLogo={hasLogo}
            hasClose={hasClose}
            hasBack={hasBack}
            isModal={true}
          />
          <div className={styles.innerWrapper}>{children}</div>
          {hasFooter && <Footer />}
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;
