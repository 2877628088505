import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';

const StyledTextField = ({ fontColor, fontWeight, autoComplete = 'off', ...rest }) => {

  const handleValidation = (event) => {
    const input = event.target;
    if (!input.value) {
      input.setCustomValidity("请填写此字段");
    } else {
      input.setCustomValidity("");
    }
  };

  return (
    <TextField
      variant="filled"
      fullWidth
      size="small"
      autoComplete={autoComplete}
      onInvalid={(e) => handleValidation(e)}
      sx={{
        input: {
          '&::placeholder': {
            opacity: 1,
            color: 'var(--mui-palette-text-tertiary)',
          },
        },
      }}
      InputLabelProps={{
        shrink: true,
        style: { color: 'var(--mui-palette-text-tertiary)', fontSize: 16 },
      }}
      {...rest}
    />
  );
};

export default StyledTextField;
