const IconAlipay = ({ isActive, color }) => {
  return (
    <svg
      width="15"
      height="15"
      fill={`${isActive ? '#ffffff' : color}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.4625 0C13.1165 0.185762 13.6734 0.527235 13.9988 1.13733C14.058 1.24843 14.25 1.70418 14.25 1.79843V9.37914C12.6898 9.04859 11.2122 8.43986 9.7125 7.92173C10.2011 6.99429 10.5833 6.02041 10.8583 5.00828H8.15417V4.00662H11.4542V3.46026H8.15417V1.86672H6.66458C6.64396 1.86672 6.55 2.04565 6.55 2.07161V3.46026H3.20417V4.00662H6.55V5.00828H3.8V5.50911H9.1625C9.01767 6.18477 8.728 6.82219 8.39892 7.42409C7.71783 7.26428 7.04775 7.01341 6.35842 6.85997C5.38125 6.6428 4.35642 6.46341 3.37742 6.75116C1.00692 7.44822 0.888667 10.5725 3.27108 11.3383C5.34137 12.0035 7.57346 10.7805 8.80454 9.16105C8.85771 9.13009 8.93242 9.14876 8.98879 9.16378C9.43475 9.28216 10.2602 9.76978 10.7355 9.97877C11.8942 10.4887 13.0675 10.9672 14.2285 11.4717C14.3728 12.5202 13.6125 13.434 12.6454 13.75H2.10417C1.27321 13.4627 0.701208 12.8244 0.5 11.9743V1.82119C0.674167 1.11821 1.08575 0.538618 1.73567 0.203063L2.33333 0H12.4625Z" />
    </svg>
  );
};

export default IconAlipay;
