const IconProfile = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.3982 1.92879C18.2318 1.81137 16.8752 0 14.2042 0H5.79578C3.12445 0 1.76629 1.8127 1.60176 1.92879C0.568867 2.96172 0 4.33504 0 5.79578V14.2042C0 16.8755 1.8127 18.2337 1.92879 18.3982C2.96172 19.4312 4.33504 20 5.79578 20H14.2042C15.665 20 17.0383 19.4311 18.0712 18.3982C18.1886 18.2318 20 16.8752 20 14.2042V5.79578C20 4.33504 19.4312 2.96172 18.3982 1.92879ZM14.2042 18.4375H5.79578C4.79836 18.4375 3.85801 18.0662 3.13258 17.389C6.50738 12.6735 13.4984 12.6817 16.8674 17.389C16.142 18.0662 15.2016 18.4375 14.2042 18.4375ZM6.17188 8.46356C6.17188 6.3527 7.88918 4.63543 10 4.63543C12.1108 4.63543 13.8281 6.3527 13.8281 8.46356C13.8281 10.5744 12.1108 12.2917 10 12.2917C7.88918 12.2917 6.17188 10.5744 6.17188 8.46356ZM18.4375 14.2042C18.4375 14.9065 18.2534 15.5805 17.9081 16.1711C16.7174 14.6358 15.0874 13.4487 13.1841 12.8109C14.5211 11.8289 15.3906 10.2458 15.3906 8.46356C15.3906 5.49117 12.9724 3.07293 10 3.07293C7.02762 3.07293 4.60938 5.49117 4.60938 8.46356C4.60938 10.2458 5.47891 11.8289 6.8159 12.8109C4.91402 13.4483 3.28355 14.6346 2.09191 16.1711C1.74656 15.5805 1.5625 14.9065 1.5625 14.2042V5.79578C1.5625 4.75238 1.96883 3.77145 2.7066 3.03367C2.87203 2.91473 3.83855 1.5625 5.79578 1.5625H14.2042C16.172 1.5625 17.1222 2.91055 17.2934 3.03367C18.0312 3.77145 18.4375 4.75242 18.4375 5.79578V14.2042Z"
        fill={color} />
    </svg>

  );
};

export default IconProfile;
