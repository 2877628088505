import React from 'react';
import { useTheme } from '@mui/material/styles';

const ThemeIcon = ({
  IconComponent,
  type = 'icon',
  level = 'primary',
  iconActive = false,
  activeColor = null,
  ...otherProps
}) => {
  const theme = useTheme();
  const aColor = theme.palette[type][activeColor] ? theme.palette[type][activeColor] : '#FFFFFF';
  const color = theme.palette[type][level] ? theme.palette[type][level] : '#59657A';

  return <IconComponent color={iconActive ? aColor : color} {...otherProps} />;
};

export default ThemeIcon;
