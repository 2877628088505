import { ACTIONS } from '../constants/actions';

export const setLiveSupportUrl = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.PUBLIC.LIVE_SUPPORT_URL,
  });

export const setBaseSettingDetails = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.PUBLIC.BASE_SETTING_DETAILS,
  });

export const setExternalLinksList = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.PUBLIC.EXTERNAL_LINKS_LIST,
  });

export const setIsEmailOTPActive = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.PUBLIC.ISEMAIL_OTP_ACTIVE,
  });

// if Toggle Registration With Email Verification Code basesetting set to 3,  open up email verify in verify page, register page no need bind nor show email
export const setOnlyVerifyPageEmailOTP = (dispatch, payload) =>
  dispatch({
    payload,
    type: ACTIONS.PUBLIC.ONLY_VERIFY_PAGE_EMAIL_OTP,
  });
