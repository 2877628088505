import { ACTIONS } from '../constants/actions';

const initalState = {
  verifyCountdownTimerMobile: 0,
  verifyCountdownTimer: 0,
  removeTimer: false,
  timer: 0,
  timerMobile: 0,
  isCountDownDisabled: false,
  isCountDownDisabledMobile: false,
};

export default function countdownReducer(countdown = initalState, { type, payload }) {
  switch (type) {
    case ACTIONS.COUNTDOWN.VERIFY_COUNTDOWN_TIMER:
      return { ...countdown, verifyCountdownTimer: payload };
    case ACTIONS.COUNTDOWN.VERIFY_COUNTDOWN_TIMER_MOBILE:
      return { ...countdown, verifyCountdownTimerMobile: payload };
    case ACTIONS.COUNTDOWN.REMOVED_TIMER:
      return { ...countdown, removeTimer: payload };
    case ACTIONS.COUNTDOWN.TIMER:
      return { ...countdown, timer: payload };
    case ACTIONS.COUNTDOWN.TIMER_MOBILE:
      return { ...countdown, timerMobile: payload };
    case ACTIONS.COUNTDOWN.IS_COUNTDOWN_DISABLED:
      return { ...countdown, isCountDownDisabled: payload };
    case ACTIONS.COUNTDOWN.IS_COUNTDOWN_DISABLED_MOBILE:
      return { ...countdown, isCountDownDisabledMobile: payload };
    default:
      return countdown;
  }
}
