const IconBankCardMenu = ({ width = 26, height = 18, color = 'white' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_16510_23605)">
        <path
          d="M17.2096 15H2.79036C1.25239 15 0 13.7676 0 12.2522V2.74779C0 1.23239 1.25239 0 2.79036 0H17.2096C18.7485 0 20 1.23239 20 2.74779V12.2522C20 13.7676 18.7476 15 17.2096 15ZM2.79036 1.71782C2.21373 1.71782 1.74443 2.17996 1.74443 2.74779V12.2522C1.74443 12.82 2.21373 13.2822 2.79036 13.2822H17.2096C17.7863 13.2822 18.2556 12.82 18.2556 12.2522V2.74779C18.2556 2.17996 17.7863 1.71782 17.2096 1.71782H2.79036Z"
          fill={color}
        />
        <path
          d="M15.0577 5.49648H8.89492C8.41379 5.49648 8.02271 5.11136 8.02271 4.63757C8.02271 4.16378 8.41379 3.77866 8.89492 3.77866H15.0577C15.5398 3.77866 15.9299 4.16289 15.9299 4.63757C15.9299 5.11226 15.5388 5.49648 15.0577 5.49648Z"
          fill={color}
        />
        <path d="M19.1287 7.5H0.872192V11.3933H19.1287V7.5Z" fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_16510_23605">
          <rect width="20" height="15" fill="white" />
        </clipPath>
      </defs>
    </svg>

    // <svg
    //   width={width}
    //   height={height}
    //   viewBox="0 0 20 15"
    //   fill="none"
    //   xmlns="http://www.w3.org/2000/svg"
    // >
    //   <path
    //     d="M17.2096 15H2.79036C1.25239 15 0 13.7676 0 12.2522V2.74779C0 1.23239 1.25239 0 2.79036 0H17.2096C18.7485 0 20 1.23239 20 2.74779V12.2522C20 13.7676 18.7476 15 17.2096 15ZM2.79036 1.71782C2.21373 1.71782 1.74443 2.17996 1.74443 2.74779V12.2522C1.74443 12.82 2.21373 13.2822 2.79036 13.2822H17.2096C17.7863 13.2822 18.2556 12.82 18.2556 12.2522V2.74779C18.2556 2.17996 17.7863 1.71782 17.2096 1.71782H2.79036Z"
    //     fill={color}
    //   />
    // </svg>
  );
};

export default IconBankCardMenu;
