import React from 'react';

import { MobileImages } from 'common/constants/image-resource';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import styles from './NoData.module.css';
import { useIntl } from 'react-intl';

const NoData = () => {
  const intl = useIntl();
  return (
    <div className={styles.noData}>
      <LazyLoadImage src={MobileImages.slots.noData} alt="nodata" width={100} height={90} />
      <div className={styles.noDataTxt}>{intl.formatMessage({ id: 'app.no_result' })}</div>
    </div>
  );
};

export default NoData;
