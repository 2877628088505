import { fontWeight } from '@mui/system';

export const theme = {
  colorSchemes: {
    light: {
      // palette for light mode
      palette: {
        bg: {
          primary: '#FFFFFF',
          secondary: '#F9F9F9',
          tertiary: '#EFEFEF',
          quaternary: '#D3D3D3',
          fifth: '#F0F0F0',
          sixth: '#FAFAFA',
          action1: 'linear-gradient(to bottom, #29BEFF, #059AFF)',
          action2: 'linear-gradient(180deg, #12162A 0%, #2B3045 100%)',
          active: '#1883FF',
          warning: '#FF6F00',
          commonFrameNumBg: '#FCBC00',
        },
        border: {
          primary: '#29BEFF',
          secondary: 'rgba(0, 0, 0, 0.12)',
          tertiary: '#EFEFEF',
          quaternary: '#D3D3D3',
          warning: '#FF6F00',
          success: '#149F00',
          danger: '#D81A00',
          action1: 'linear-gradient(0deg, #272C43 0%, #202437 100%)',
        },
        text: {
          primary: '#000000',
          secondary: '#313131',
          tertiary: '#737272',
          success1: '#4CC612', // brighter
          success2: '#149F00', // darker
          danger1: '#FC0B36', // brighter
          danger2: '#D81A00', // darker
          warning: '#FF6F00',
          info: '#3E80FF',
          link: '#00C2FF',
          common: '#FFFFFF',
          black: '#2b154c',
          yellow: '#ffd300',
        },
        icon: {
          primary: '#737272',
          secondary: '#000000',
          tertiary: '#D3D3D3',
          active: '#313131',
          black: '#000000',
        },
        button: {
          primary: '#ffffff',
        },
        // Component specific
        component: {
          bg: {
            footer: '#101A31',
          },
          footer: {
            footerIcon: 'linear-gradient(180deg, #68dae0 0.03%, #2071ff 100%)',
          },
          sidebar: {
            downloadContainerBg: 'linear-gradient(180deg, #29BEFF 0%, #059AFF 100%)',
          },
          topSnackBar: {
            welcomeBonusBg: '#FCBC00',
            affiliateBg: '#F7EAB3',
          },
          commonPrompt: {
            cancelBtnBg: 'linear-gradient(180deg, #12162a 0%, #2b3045 100%)',
          },
        },
        image: {},
      },
    },
    dark: {
      // palette for dark mode
      palette: {
        bg: {
          primary: '#FFFFFF',
          secondary: '#EEEEEE',
          tertiary: '#FFFFFF',
          quaternary: '#962020',
          fifth: '#E3E3E3',
          sixth: '#FAFAFA',
          action1: 'linear-gradient(180deg, #FFE391 0%, #F9C600 100%)',
          action2: 'linear-gradient(180deg, #12162A 0%, #2B3045 100%)',
          action3: 'linear-gradient(180deg, #FFAC5F 0%, #FA7F0D 100%)',
          active: '#FF6F00',
          warning: '#FF6F00',
          navBg: '#C83434',
          commonFrameNumBg: '#FCBC00',
        },
        border: {
          primary: '#29BEFF',
          secondary: 'rgba(0, 0, 0, 0.12)',
          tertiary: '#1A243E',
          quaternary: '#223858',
          warning: '#FF6F00',
          success: '#149F00',
          danger: '#D81A00',
          action1: 'linear-gradient(0deg, #272C43 0%, #202437 100%)',
          line: '#CFCFCF',
        },
        text: {
          primary: '#000000',
          secondary: '#333333',
          tertiary: '#6E6E6E',
          quaternary: '#676F7E',
          fifth: '#EEEEEE',
          white: '#FFFFFF',
          secondaryWhite: '#FAFAFA',
          success1: '#4CC612', // brighter
          success2: '#149F00', // darker
          danger1: '#FC0B36', // brighter
          danger2: '#D81A00', // darker
          warning: '#FF6F00',
          info: '#FF9A4D',
          link: '#FBCE50',
          black: '#2b154c',
          yellow: '#ffd300',
          secondaryYellow: '#FED500',
          tertiaryYellow: '#F3E486',
        },
        icon: {
          primary: '#59657A',
          secondary: '#962020',
          tertiary: '#6E6E6E',
          active: '#FFFFFF',
          black: '#000000',
          yellow: '#FCBC00',
        },
        button: {
          primary: '#ffffff',
        },
        // Component specific
        component: {
          bg: {
            footer: '#101A31',
          },
          footer: {
            footerIcon: 'linear-gradient(180deg, #FFE391 0%, #F9C600 100%)',
          },
          sidebar: {
            downloadContainerBg: 'linear-gradient(180deg, #29BEFF 0%, #059AFF 100%)',
          },
          topSnackBar: {
            welcomeBonusBg: '#FCBC00',
            affiliateBg: '#F7EAB3',
          },
          commonPrompt: {
            cancelBtnBg: 'linear-gradient(180deg, #12162a 0%, #2b3045 100%)',
          },
          progressBar: {
            frameBg: '#FBCE50',
            barColor: '#FBCE50',
          },
          navigateMenu: {
            selecetedCategoryBg: '#F7EAB3',
          },
          wallet: {
            borderBg: 'rgba(255, 227, 145, 1)',
            bonusContainerBg: 'linear-gradient(180deg, #962020 -13.44%, #C83434 71.19%)',
          },
          vip: {
            arrowBg: '#571C1D',
            progressBg: `linear-gradient(180deg, #000000 -149.06%, #962020 176.29%, #C83434 234.38%)`,
          },
          dailyLogin: {
            claimBtnBg: '#250C1B',
          },
          depositAppBonus: {
            amtBg: `linear-gradient(360deg, #C83434 0%, #962020 100%)`,
            amtTextBg: '#F7E68A',
          },
          dailyWeeklyBonus: {
            tabActiveBg: '#F72C4D',
            darkBg: '#A30000',
            secondaryBg: '#A81818',
            tertiaryBg: '#920000',
            quaternaryBg: '#FF153F99',
            fifthBg: '#46101A',
            borderBg: '#FF7F93',
            claimBtnTextBg: '#5D2C00',
            claimBtnBg: '#FFC529',
            claimedBtnBg: '#00A93A',
          },
        },
        image: {},
      },
    },
  },
  components: {
    MuiPopover: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaper-root': {
            backgroundColor: `${theme.palette.bg.quaternary} !important`,
            border: 'none !important',
          },
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            border: 'none !important',
            backgroundColor: `${theme.palette.bg.primary} !important`,
            borderRadius: 10,
            color: theme.palette.text.primary,
          },
        }),
      },
    },

    MuiButtonBase: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiButton-containedPrimary': {
            background: `${theme.palette.bg.action1} `,
          },
          '&.Mui-selected': {
            backgroundColor: 'transparent !important',
          },
          '&.Mui-selected:hover': {
            backgroundColor: 'transparent !important',
          },
        }),
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
          marginBottom: 10,
          '& .MuiTextField-root': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'OpenSans, sans-serif',
          letterSpacing: ' 0.04em',
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 4,
          color: theme.palette.text.primary,
          backgroundColor: `${theme.palette.bg.fifth} !important`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          '& .MuiFilledInput-input': {
            fontFamily: 'OpenSans, sans-serif',
            fontSize: 14,
            color: theme.palette.text.tertiary,
            // fontWeight: 600,
            height: 'auto',
            paddingTop: 12,
            paddingBottom: 12,
            [theme.breakpoints.up('sm')]: {
              fontSize: 16,
            },
            '&.Mui-disabled': {
              WebkitTextFillColor: theme.palette.text.tertiary,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: theme.palette.bg.fifth,
          },
          '&:hover': {
            backgroundColor: theme.palette.bg.fifth,
          },
          '& .MuiInputAdornment-root.MuiInputAdornment-positionStart.MuiInputAdornment-root:not(.MuiInputAdornment-hiddenLabel)':
            {
              marginTop: 0,
              paddingLeft: 8,
              paddingRight: 4,
            },
          '&:hover:not(.Mui-disabled, .Mui-error):before': {
            borderBottom: '0px',
          },
          '&.Mui-focused': {
            backgroundColor: theme.palette.bg.fifth,
          },
        }),
        underline: {
          '&:hover:before': {
            borderBottom: '0px',
          },
          '&::before': {
            borderBottom: '0px',
          },
          '&::after': {
            borderBottom: '0px',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontFamily: 'OpenSans, sans-serif',
          color: 'rgba(0, 0, 0, 0.54)',
          '&.MuiInputLabel-root.Mui-focused': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiDrawer-paper': {
            backgroundColor: theme.palette.bg.primary,
            backgroundImage: 'none',
          },
          '&.MuiDialog-paper': {
            backgroundColor: theme.palette.bg.secondary,
            color: theme.palette.text.primary,
          },
          '&.MuiPickersPopper-paper': {
            backgroundColor: theme.palette.bg.secondary,
            color: theme.palette.text.primary,
          },
          '.MuiPickersCalendarHeader-switchViewButton': {
            backgroundColor: 'var(--mui-palette-text-link)',
          },
          '.MuiPickersArrowSwitcher-button': {
            color: 'var(--mui-palette-text-primary)',
          },
          '.MuiPickersDay-root': {
            '&.Mui-selected': {
              backgroundColor: 'var(--mui-palette-text-link) !important',
            },
            '&.Mui-selected:hover': {
              backgroundColor: 'var(--mui-palette-bg-warning)',
              color: 'var(--mui-palette-text-primary)',
            },
          },
          '.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
            color: 'var(--mui-palette-text-tertiary)',
          },
          '.MuiPickersMonth-monthButton.Mui-selected': {
            backgroundColor: 'var(--mui-palette-bg-warning)',
          },
          '& .MuiPickersYear-yearButton': {
            color: 'var(--mui-palette-text-primary)',
            '&.Mui-selected': {
              backgroundColor: 'var(--mui-palette-text-link) !important',
            },
            '&.Mui-disabled': {
              color: 'var(--mui-palette-text-tertiary)',
            },
          },
          '.MuiDialogActions-root .MuiButton-root': {
            color: 'var(--mui-palette-text-primary)',
          },
          '& .MuiMenu-list': {
            backgroundColor: 'var(--mui-palette-bg-fifth)',
          },
        }),
      },
    },
    MuiModal: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiBackdrop-root': {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
          },
        }),
      },
    },
  },
};
