import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, WALLET } = API;

const WalletApi = {
  fetchDepositChannels: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.DEPOSIT_CHANNEL}`);
    return response;
  },
  submitDeposit: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.DEPOSIT}`, data);
    return response;
  },
  fetchBindingList: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_LIST}`);
    return response;
  },
  submitWithdraw: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.WITHDRAW}`, data);
    return response;
  },
  fetchWithdrawalInfo: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.WITHDRAW_INFO}`);
    return response;
  },
  submitVault: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.VAULT}`, data);
    return response;
  },
  fetchBalance: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.BALANCE}`);
    return response;
  },
  fetchVaultLog: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.VAULT_LOG}`);
    return response;
  },
  fetchDepositBindingData: async () => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.DEPOSIT_BINDING_DATA}`);
    return response;
  },
  submitDepositBindingData: async (data) => {
    const response = await axios.post(
      `${HOST}${APIBASE}${WALLET.SUBMIT_DEPOSIT_BINDING_DATA}`,
      data,
    );
    return response;
  },
  fetchBankBranch: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.GET_BANK_BRANCH}`);
    return response;
  },
  fetchBindingGet: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_GET}`);
    return response;
  },
  fetchBindingFilter: async () => {
    const response = await axios.get(`${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_FILTER}`);
    return response;
  },
  submitBinding: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.WITHDRAW_BINDING_ADD}`, data);
    return response;
  },
  getDepositSetting: async (param) => {
    const response = await axios.get(
      `${HOST}${APIBASE}${WALLET.DEPOSIT_CHANNEL_SETTINGS}/${param}`,
    );
    return response;
  },
  getDeposit: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${WALLET.GET_DEPOSIT}`, data);
    return response;
  },
};

export default WalletApi;
