import React from 'react';
import { DatePicker, DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { zhCN } from '@mui/x-date-pickers/locales';
import { InputAdornment } from '@mui/material';
import { useIntl } from 'react-intl';
import IconDate from 'component/mobile/common/icon/IconDate';
import IconSearch from 'component/mobile/common/icon/IconSearch';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import styles from './CustomDatePicker.module.css';
import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
const slotProps = {
  toolbar: { hidden: true },
  textField: {
    fullWidth: true,
    variant: 'filled',
    size: 'small',
    InputLabelProps: {
      shrink: true,
      style: { color: 'var(--mui-palette-text-tertiary)', fontSize: 16 },
    },
    ...(isMobile && {
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <ThemeIcon IconComponent={IconDate} />
          </InputAdornment>
        ),
      },
    }),
  },
};

const CustomDatePicker = (props) => {
  const PickerComponent = isMobile ? MobileDatePicker : DesktopDatePicker;
  const { startDate, endDate, handleChange, handleSubmit, startDateFieldName = 'startDate', endDateFieldName = 'endDate' } = props;
  const intl = useIntl();

// Set default values to currentdate
  const defaultStartDate = dayjs(startDate) || dayjs();
  const defaultEndDate = dayjs(endDate) || dayjs();

  return (
    <>
      <LocalizationProvider
        localeText={zhCN.components.MuiLocalizationProvider.defaultProps.localeText}
        dateAdapter={AdapterDayjs}
        adapterLocale="zh-cn"
      >
        <div className={styles.dateRowWrapper}>
          <div className={styles.dateRow}>
            <PickerComponent
              slots={{ openPickerIcon: IconDate }}
              slotProps={slotProps}
              disableFuture
              minDate={dayjs().subtract(6, 'month')}
              maxDate={dayjs(endDate)}
              value={defaultStartDate}
              className={styles.date}
              onChange={handleChange(startDateFieldName)}
              name="startDate"
            />
            <div className={styles.to}> {intl.formatMessage({ id: 'transaction.to' })}</div>
            <PickerComponent
              slots={{ openPickerIcon: IconDate }}
              slotProps={slotProps}
              disableFuture
              value={defaultEndDate}
              className={styles.date}
              onChange={handleChange(endDateFieldName)}
              name="endDate"
            />
          </div>
          <div className={styles.search} onClick={handleSubmit}>
            <ThemeIcon IconComponent={IconSearch} />
          </div>
        </div>
      </LocalizationProvider>
    </>
  );
};

export default CustomDatePicker;
