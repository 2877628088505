import axios from 'axios';
import { API } from 'common/constants/api';

const { HOST, APIBASE, TRANSACTION } = API;

const TransactionApi = {
  fetchTransactions: async (data) => {
    const response = await axios.post(`${HOST}${APIBASE}${TRANSACTION.GET}`, data);
    return response;
  },
  //   getTransactionFilter: async () => {
  //     const response = await axios.get(`${HOST}${APIBASE}${TRANSACTION.GET_FILTER}`);
  //     return response;
  //   },
};

export default TransactionApi;
