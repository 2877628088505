const IconRakeBack = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.7143 6.15625L16 17.125H4L2.28571 6.15625M17.7143 6.15625L13 10.2695L10 5.24219M17.7143 6.15625C18.4243 6.15625 19 5.54239 19 4.78516C19 4.02792 18.4243 3.41406 17.7143 3.41406C17.0042 3.41406 16.4286 4.02792 16.4286 4.78516C16.4286 5.54239 17.0042 6.15625 17.7143 6.15625ZM2.28571 6.15625L7 10.2695L10 5.24219M2.28571 6.15625C2.9958 6.15625 3.57143 5.54239 3.57143 4.78516C3.57143 4.02792 2.9958 3.41406 2.28571 3.41406C1.57563 3.41406 1 4.02792 1 4.78516C1 5.54239 1.57563 6.15625 2.28571 6.15625ZM10 5.24219C10.7101 5.24219 11.2857 4.62833 11.2857 3.87109C11.2857 3.11386 10.7101 2.5 10 2.5C9.28994 2.5 8.71429 3.11386 8.71429 3.87109C8.71429 4.62833 9.28994 5.24219 10 5.24219Z"
        stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default IconRakeBack;
