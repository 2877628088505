const IconFailed = () => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0001 19.36C16.3462 19.36 20.6801 15.0261 20.6801 9.68C20.6801 4.33388 16.3462 0 11.0001 0C5.65395 0 1.32007 4.33388 1.32007 9.68C1.32007 12.6902 2.6941 15.3795 4.8492 17.1549C4.85263 17.1578 4.84957 17.1632 4.84536 17.1618C4.84277 17.1609 4.84008 17.1629 4.84008 17.1656V19.3999C4.84008 20.5924 6.09701 21.3664 7.16188 20.8296L9.39038 19.7062C9.88711 19.4558 10.4438 19.36 11.0001 19.36Z"
        fill="#FC0B36"
      />
      <path
        d="M14.4484 7.19283C14.7395 6.90173 14.7395 6.42978 14.4484 6.13868L14.2897 5.98004C13.9986 5.68895 13.5267 5.68895 13.2356 5.98004L11 8.2156L8.76448 5.98004C8.47339 5.68895 8.00143 5.68895 7.71034 5.98004L7.55169 6.13868C7.2606 6.42978 7.2606 6.90173 7.55169 7.19283L9.78725 9.42839L7.55169 11.6639C7.2606 11.955 7.2606 12.427 7.55169 12.7181L7.71034 12.8767C8.00143 13.1678 8.47339 13.1678 8.76448 12.8767L11 10.6412L13.2356 12.8767C13.5267 13.1678 13.9986 13.1678 14.2897 12.8767L14.4484 12.7181C14.7395 12.427 14.7395 11.955 14.4484 11.6639L12.2128 9.42839L14.4484 7.19283Z"
        fill="white"
      />
    </svg>
  );
};

export default IconFailed;
