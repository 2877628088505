import React, { useCallback, useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { TRANSACTION_TYPE } from 'common/constants/keys';
import dayjs from 'dayjs';
import { useIntl } from 'react-intl';
import CommonModal from 'component/mobile/common/commonModal/CommonModal';
import RecordList from './recordlist/RecordList';
import { useGetTransaction } from 'services/query/transactionQuery';
import { useLoading } from 'component/shared/provider/LoadingProvider';
import CustomDatePicker from 'component/shared/customDatePicker/CustomDatePicker';
import styles from './RecordPage.module.css';

const initFormData = {
  status: 999, // default is all
  method: 999, // default is all
  game: 999, // default is all
  startDate: new Date(),
  endDate: new Date(),
};

const initListData = {
  type: null,
  data: [],
  pageIndex: 1,
  pageCount: 1,
};

const initTotalBH = {
  bet: 0,
  winLoss: 0,
};

const RecordPage = (props) => {
  //Utils
  const { type } = useParams();
  const intl = useIntl();
  const { isLoading } = useLoading();
  const firstRender = useRef(true);
  //State
  const [formData, setFormData] = useState(initFormData);
  const [listData, setListData] = useState(initListData);
  const [totalAmt, setTotalAmt] = useState(0);
  const [totalBH, setTotalBH] = useState(initTotalBH);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [lock, setLock] = useState(false);
  const [showData, setShowData] = useState(false);
  const [params, setParams] = useState(null);
  //Axios hook
  const [{ data: tData, loading: tLoading }, getRecord] = useGetTransaction();

  const handleChange = useCallback(
    (prop) => (value) => {
      setFormData((data) => ({ ...data, [prop]: value }));
    },
    [],
  );

  const resetData = useCallback(() => {
    setListData(initListData);
    setTotalBH(initTotalBH);
    setTotalAmt(0);
    setShowData(false);
    setHasMoreData(true);
  }, []);

  const searchResult = useCallback(
    (pageIndex = null) => {
      setLock(true);
      const searchTypeKey = type ? type.toUpperCase() : null;
      if (!searchTypeKey || !TRANSACTION_TYPE[searchTypeKey]) return;

      const params = {
        searchType: TRANSACTION_TYPE[searchTypeKey],
        startDate: formData.startDate
          ? dayjs(formData.startDate).format('YYYY-MM-DD HH:mm:ss')
          : null,
        endDate: formData.endDate ? dayjs(formData.endDate).format('YYYY-MM-DD HH:mm:ss') : null,
        status: formData.status === 999 ? null : formData.status,
        paymentMethod: formData.method === 999 ? null : formData.method.toString(),
        game: formData.game === 999 ? null : formData.game.toString(),
        pageIndex: pageIndex,
        pageSize: null,
      };
      getRecord(params);
    },
    [formData, type, params],
  );

  const handleSubmit = useCallback(() => {
    if (!lock) {
      resetData();
      searchResult();
    }
  }, [resetData, searchResult, lock]);

  const fetchNextHandler = useCallback(
    (e) => {
      let nextPageIndex = null;
      if (listData.data.length > 0 && hasMoreData) {
        if (listData.pageIndex < listData.pageCount) {
          nextPageIndex = listData.pageIndex + 1;
        } else {
          setHasMoreData(false);
          return;
        }
      }
      if (!lock) {
        searchResult(nextPageIndex);
      }
    },
    [hasMoreData, listData, lock, searchResult],
  );

  useEffect(() => {
    setLock(false);
    if (tData) {
      setShowData(true);
    }
    if (tData && tData.deposit) {
      setListData((prevListData) => ({
        type: 0,
        data: [...prevListData.data, ...tData.deposit.data],
        pageIndex: tData.deposit.pageIndex,
        pageCount: tData.deposit.pageCount,
      }));

      setTotalAmt(tData.deposit.totalAmt);
    }

    if (tData && tData.withdrawal) {
      setListData((prevListData) => ({
        type: 1,
        data: [...prevListData.data, ...tData.withdrawal.data],
        pageIndex: tData.withdrawal.pageIndex,
        pageCount: tData.withdrawal.pageCount,
      }));

      setTotalAmt(tData.withdrawal.totalAmt);
    }

    if (tData && tData.bonus) {
      setListData((prevListData) => ({
        type: 4,
        data: [...prevListData.data, ...tData.bonus.data],
        pageIndex: tData.bonus.pageIndex,
        pageCount: tData.bonus.pageCount,
      }));
    }

    if (tData && tData.betHistory) {
      setListData((prevListData) => ({
        type: 2,
        data: [...prevListData.data, ...tData.betHistory.data],
        pageIndex: tData.betHistory.pageIndex,
        pageCount: tData.betHistory.pageCount,
      }));

      setTotalBH({
        bet: tData.betHistory.totalAmt,
        winLoss: tData.betHistory.totalWinLoss,
        turnover: tData.betHistory.totalTurnover,
      });
    }

    if (tData && tData.adjustment) {
      setListData((prevListData) => ({
        type: 3,
        data: [...prevListData.data, ...tData.adjustment.data],
        pageIndex: tData.adjustment.pageIndex,
        pageCount: tData.adjustment.pageCount,
      }));

      setTotalAmt(0); //clear
    }
  }, [tData, lock]);

  useEffect(() => {
    isLoading('transaction', tLoading);
  }, [tLoading, isLoading]);

  useEffect(() => {
    if (firstRender.current) {
      searchResult();
      //set timeout to make sure calling transactin api after first render
      firstRender.current = false;
    }
  }, [searchResult, firstRender]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        {`${intl.formatMessage({ id: `transaction.${type?.toLowerCase() || ''}` })}${intl.formatMessage({ id: 'transaction.records' })}`}
      </div>
      <CustomDatePicker
        startDate={formData.startDate}
        endDate={formData.endDate}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
      <RecordList
        listData={listData}
        totalAmt={totalAmt}
        totalBH={totalBH}
        fetchNextHandler={fetchNextHandler}
        hasMoreData={hasMoreData}
        showData={showData}
      />
    </div>
  );
};

export default RecordPage;
