const IconWithdraw = ({ color }) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11695_2669)">
        <path
          d="M10 14.6354C10.4315 14.6354 10.7813 14.2857 10.7813 13.8542V8.05797L12.5205 9.79719C12.8256 10.1023 13.3203 10.1023 13.6254 9.79719C13.9305 9.49203 13.9304 8.99742 13.6254 8.69234L10.5524 5.61941C10.2468 5.31387 9.75238 5.31465 9.44754 5.61941L6.37461 8.69234C6.06957 8.99746 6.06953 9.49211 6.37461 9.79719C6.67973 10.1023 7.17441 10.1023 7.47949 9.79719L9.21875 8.05797V13.8542C9.21875 14.2857 9.56852 14.6354 10 14.6354ZM5.79578 20H14.2042C16.8753 20 18.2318 18.1886 18.3982 18.0712C19.4312 17.0384 20 15.665 20 14.2042V5.79578C20 4.335 19.4311 2.96168 18.3982 1.92879C18.2335 1.81254 16.8755 0 14.2042 0L5.79578 0C3.12477 0 1.76813 1.81145 1.60176 1.92883C0.568867 2.96168 0 4.33504 0 5.79578L0 14.2042C0 15.665 0.568867 17.0384 1.6018 18.0712C1.76633 18.1873 3.12441 20 5.79578 20ZM5.79578 18.4375C3.8384 18.4375 2.87184 17.0851 2.70664 16.9663C1.96879 16.2286 1.5625 15.2476 1.5625 14.2042L1.5625 5.79578C1.5625 4.75238 1.96883 3.77145 2.7066 3.03367C2.87777 2.91055 3.82801 1.5625 5.79578 1.5625L14.2042 1.5625C16.1616 1.5625 17.1279 2.91465 17.2934 3.03363C18.0312 3.77145 18.4375 4.75238 18.4375 5.79578V14.2042C18.4375 15.2476 18.0312 16.2286 17.2934 16.9663C17.1222 17.0894 16.172 18.4375 14.2042 18.4375H5.79578Z"
          fill={color} />
      </g>
      <defs>
        <clipPath id="clip0_11695_2669">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default IconWithdraw;
