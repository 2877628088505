import { useMutation } from '@tanstack/react-query';
import { formatResult } from 'common/helpers/helper';
import TransactionApi from './api/transactionApi';
import { QUERY_KEYS } from 'common/constants/query-key';

const { TRANSACTION } = QUERY_KEYS;

/******************
 * Handle useQuery
 ******************/

export const useGetTransaction = () => {
  const mutation = useMutation({
    // queryKey: [TRANSACTION.TRANSACTION_RECORD, { params }],
    mutationFn: (params) => TransactionApi.fetchTransactions(params),
    // enabled: params !== null,
  });
  return formatResult(mutation);
};
