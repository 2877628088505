const IconSideMenu = ({ isActive, color, width = 28, height = 28 }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          y="9.51953"
          width="10.5778"
          height="2.96113"
          rx="1.48057"
          fill={!isActive ? color : 'white'}
        />
        <rect
          width="22"
          height="2.96113"
          rx="1.48057"
          fill={!isActive ? color : 'white'}
        />
        <rect
          y="19.0389"
          width="22"
          height="2.96113"
          rx="1.48057"
          fill={!isActive ? color : 'white'}
        />
        {isActive ? (
          <path
            d="M16.2204 12.2893L12.9113 15.5984C12.7021 15.8076 12.4358 15.9122 12.1125 15.9122C11.7892 15.9122 11.523 15.8076 11.3138 15.5984C11.1046 15.3892 11 15.123 11 14.7997C11 14.4764 11.1046 14.2101 11.3138 14.0009L14.6229 10.6918L11.3138 7.41128C11.1046 7.20209 11 6.93584 11 6.61254C11 6.28924 11.1046 6.02299 11.3138 5.81379C11.523 5.6046 11.7892 5.5 12.1125 5.5C12.4358 5.5 12.7021 5.6046 12.9113 5.81379L16.2204 9.12288L19.5009 5.81379C19.7101 5.6046 19.9764 5.5 20.2997 5.5C20.623 5.5 20.8892 5.6046 21.0984 5.81379C21.3266 6.04201 21.4407 6.31301 21.4407 6.6268C21.4407 6.94059 21.3266 7.20209 21.0984 7.41128L17.7893 10.6918L21.0984 14.0009C21.3076 14.2101 21.4122 14.4764 21.4122 14.7997C21.4122 15.123 21.3076 15.3892 21.0984 15.5984C20.8702 15.8266 20.5992 15.9407 20.2854 15.9407C19.9716 15.9407 19.7101 15.8266 19.5009 15.5984L16.2204 12.2893Z"
            fill={!isActive ? color : 'white'} />

        ) : (
          <path
            d="M19.8648 14.6578L16.4334 10.9027L19.8648 7.14767C20.2097 6.77023 20.2097 6.16052 19.8648 5.78308C19.5199 5.40564 18.9627 5.40564 18.6178 5.78308L14.5586 10.2253C14.2137 10.6027 14.2137 11.2124 14.5586 11.5899L18.6178 16.032C18.9627 16.4095 19.5199 16.4095 19.8648 16.032C20.2008 15.6546 20.2097 15.0352 19.8648 14.6578Z"
            fill={!isActive ? color : 'white'} />
        )
        }
      </svg>
    )
      ;
};

export default IconSideMenu;