import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbarMsg, formatResult } from 'common/helpers/helper';
import {
  setDepositChannelsResult,
  setWithdrawData,
  setWithdrawalInfo,
  setAttemptsLeft,
  setRates,
  setAttemptsDaily,
  setWithdrawInfo,
  setBalanceData,
  setHistoryData,
} from 'common/reducer-actions/walletActions';
import { setSpinChance, setUserBalance } from 'common/reducer-actions/userActions';
import { useDispatch } from '../../store/StateProvider';
import { useIntl } from 'react-intl';
import { isUserLoggedIn } from 'common/helpers/localStorage';
import WalletApi from './api/walletApi';
import { QUERY_KEYS } from 'common/constants/query-key';

const { WALLET } = QUERY_KEYS;

/******************
 * Handle useQuery/useMutation
 ******************/

export const useGetDepositChannels = () => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [WALLET.DEPOSIT_CHANNELS],
    queryFn: async () => {
      const data = await WalletApi.fetchDepositChannels();
      if (data) {
        setDepositChannelsResult(dispatch, {
          data,
        });
        return data;
      }
      //  else {
      //   enqueueSnackbarMsg(data.errorMessage, 'error');
      // }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useSubmitDeposit = () => {
  const mutation = useMutation({
    mutationFn: (params) => WalletApi.submitDeposit(params),
    onSuccess: (data) => {
      if (data) {
        return data;
      } else {
        enqueueSnackbarMsg(data.errorMessage, 'error');
      }
    },
  });
  return formatResult(mutation);
};

export const useGetBindingList = (targetMethod) => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [WALLET.BINDING_LIST],
    queryFn: async () => {
      const res = await WalletApi.fetchBindingList();
      if (res) {
        if (res.withdrawChannel.length > 0) {
          const withdata = res.withdrawChannel;
          setWithdrawData(
            dispatch,
            withdata.map((item, index) => ({
              ...item,
              active: targetMethod ? item.code === targetMethod : index === 0,
            })),
          );

          let currWithdrawlInfo;
          if (targetMethod !== 0) {
            currWithdrawlInfo = res.withdrawChannel.find((i, index) => i.code === targetMethod);
          } else {
            currWithdrawlInfo = res.withdrawChannel[0];
          }

          const currWithdrawlInfoData = {
            withdrawChannelId: currWithdrawlInfo.id,
            withdrawChannelType: currWithdrawlInfo.code,
          };
          setWithdrawalInfo(dispatch, currWithdrawlInfoData);
        }
        if (typeof res.rates !== 'undefined') {
          setRates(dispatch, res.rates);
        }
        if (typeof res.attemptsLeft !== 'undefined') {
          setAttemptsLeft(dispatch, res.attemptsLeft);
        }
        if (typeof res.attemptsDaily !== 'undefined') {
          setAttemptsDaily(dispatch, res.attemptsDaily);
        }

        return res;
      }
      // else {
      //   enqueueSnackbarMsg(res.errorMessage, 'error');
      // }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useSubmitWithdraw = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: (data) => WalletApi.submitWithdraw(data),
    onSuccess: (res) => {
      if (res) {
        if (res.message) {
          if (res.message === '提款请求已提交。') {
            enqueueSnackbarMsg(res.message);
            queryClient.invalidateQueries([WALLET.BALANCE]);
          } else {
            enqueueSnackbarMsg(res.message, 'error');
          }
        }
        return res;
      }
      // else {
      //   enqueueSnackbarMsg(res.errorMessage, 'error');
      // }
    },
  });
  return formatResult(mutation);
};

export const useGetWithdrawalInfo = () => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [WALLET.WITHDRAW_INFO],
    queryFn: async () => {
      const res = await WalletApi.fetchWithdrawalInfo();
      if (res) {
        setWithdrawInfo(dispatch, res);
        setUserBalance(dispatch, res.balance);
        return res;
      }
      // else {
      //   enqueueSnackbarMsg(res.errorMessage, 'error');
      // }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useSubmitVault = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const mutation = useMutation({
    mutationFn: async (params) => await WalletApi.submitVault(params),
    onSuccess: (res) => {
      if (res) {
        if (res.result === 0) {
          enqueueSnackbarMsg(res.errorMessage, 'error');
        }
        if (res.result === 1) {
          enqueueSnackbarMsg(intl.formatMessage({ id: 'vault.success_text' }));
          if (res.balance) {
            setBalanceData(dispatch, res.balance);
            if (res.balance.truncatedBalance)
              setUserBalance(dispatch, res.balance.truncatedBalance);
          }
          return res;
        }
      }
      // else {
      //   enqueueSnackbarMsg(res.errorMessage, 'error');
      // }
    },
  });
  return formatResult(mutation);
};

export const useGetBalance = () => {
  const dispatch = useDispatch();
  const mutation = useMutation({
    mutationFn: (params) => WalletApi.fetchBalance(),
    onSuccess: (res) => {
      if (res) {
        setUserBalance(dispatch, res.truncatedBalance);
        setBalanceData(dispatch, res);
        setSpinChance(dispatch, { spinChances: res.spinChances });
      }
    },
    meta: { preventGlobalError: true },
  });

  return formatResult(mutation);
};

export const useGetVaultLog = () => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [WALLET.VAULT_LOG],
    queryFn: async () => {
      const res = await WalletApi.fetchVaultLog();
      if (res) {
        setHistoryData(dispatch, res);
        return res;
      }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useGetDepositBindingData = () => {
  const query = useQuery({
    queryKey: [WALLET.DEPOSIT_BINDING_DATA],
    queryFn: async () => {
      const data = await WalletApi.fetchDepositBindingData();
      if (data) {
        return data;
      } else {
        enqueueSnackbarMsg(data.errorMessage, 'error');
      }
    },
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useSubmitDepositBindingData = () => {
  const mutation = useMutation({
    mutationFn: (params) => WalletApi.submitDepositBindingData(params),
    onSuccess: (data) => {
      if (data) {
        return data;
      } else {
        enqueueSnackbarMsg(data.errorMessage, 'error');
      }
    },
  });
  return formatResult(mutation);
};

export const useBindingGet = () => {
  const query = useQuery({
    queryKey: [WALLET.BINDING_GET],
    queryFn: WalletApi.fetchBindingGet,
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useBindingFilter = () => {
  const query = useQuery({
    queryKey: [WALLET.BINDING_FILTER],
    queryFn: WalletApi.fetchBindingFilter,
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useFetchBankBranch = () => {
  const query = useQuery({
    queryKey: [WALLET.GET_BANK_BRANCH],
    queryFn: WalletApi.fetchBankBranch,
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};
export const useSubmitBinding = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (params) => WalletApi.submitBinding(params),
    onSuccess: (res) => {
      if (res) {
        queryClient.invalidateQueries([WALLET.BINDING_GET]);
        enqueueSnackbarMsg(intl.formatMessage({ id: 'profile.add_bank_success' }));
        return res;
      }
    },
  });
  return formatResult(mutation);
};

export const useGetDepositSetting = (channelId) => {
  const query = useQuery({
    queryKey: [WALLET.DEPOSIT_SETTING, channelId],
    queryFn: () => WalletApi.getDepositSetting(channelId),
    enabled: false,
    cacheTime: 0,
    staleTime: 0,
    meta: { preventGlobalError: true },
  });
  return formatResult(query, true);
};

export const useGetDeposit = () => {
  const mutation = useMutation({
    mutationFn: (data) => WalletApi.getDeposit(data),
  });
  return formatResult(mutation);
};
