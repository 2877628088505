import { MobileImages } from 'common/constants/image-resource';

export const VIPPRIVILEGEENUM = {
  //1: 'Rebate Bonus',
  2: 'Daily Cash Back',
  3: 'Level Up Bonus',
  4: 'Daily Bonus',
  5: 'Weekly Bonus',
  6: 'Monthly Bonus',
  7: 'Birthday Bonus',
  8: 'VIP Subscription Bonus',
  9: 'Exclusive-Customer Service',
  10: 'VIP Tour',
  11: 'Fully Customization Experience',
  12: 'Luxury Hotel Suites',
};

export const VIPLEVELENUM = {
  iron: {
    name: 'Iron',
    level: 0,
    bonus: 0,
    requireAmt: [0, 10000],
    privilege: [1, 2],
    icon: MobileImages.vip.iron,
    iconSmall: MobileImages.vip.ironSmall,
  },
  bronze: {
    name: 'Bronze',
    level: 1,
    bonus: 100,
    requireAmt: [10000, 50000],
    privilege: [1, 2, 3],
    icon: MobileImages.vip.bronze,
    iconSmall: MobileImages.vip.bronzeSmall,
  },
  silver: {
    name: 'Silver',
    level: 2,
    bonus: 400,
    requireAmt: [50000, 100000],
    privilege: [1, 2, 3, 4, 5, 6, 7],
    icon: MobileImages.vip.silver,
    iconSmall: MobileImages.vip.silverSmall,
  },
  gold: {
    name: 'Gold',
    level: 3,
    bonus: 500,
    requireAmt: [100000, 250000],
    privilege: [1, 2, 3, 4, 5, 6, 7],
    icon: MobileImages.vip.gold,
    iconSmall: MobileImages.vip.goldSmall,
  },
  platinum1: {
    name: 'Platinum I',
    level: 4,
    bonus: 1500,
    requireAmt: [250000, 500000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8],
    icon: MobileImages.vip.platinumi,
    iconSmall: MobileImages.vip.platinum1Small,
  },
  platinum2: {
    name: 'Platinum II',
    level: 5,
    bonus: 2500,
    requireAmt: [500000, 1000000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8],
    icon: MobileImages.vip.platinumii,
    iconSmall: MobileImages.vip.platinum2Small,
  },
  platinum3: {
    name: 'Platinum III',
    level: 6,
    bonus: 5000,
    requireAmt: [1000000, 2500000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8],
    icon: MobileImages.vip.platinumiii,
    iconSmall: MobileImages.vip.platinum3Small,
  },
  platinum4: {
    name: 'Platinum IV',
    level: 7,
    bonus: 15000,
    requireAmt: [2500000, 5000000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8],
    icon: MobileImages.vip.platinumiv,
    iconSmall: MobileImages.vip.platinum4Small,
  },
  platinum5: {
    name: 'Platinum V',
    level: 8,
    bonus: 25000,
    requireAmt: [5000000, 10000000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8],
    icon: MobileImages.vip.platinumv,
    iconSmall: MobileImages.vip.platinum5Small,
  },
  platinum6: {
    name: 'Platinum VI',
    level: 9,
    bonus: 50000,
    requireAmt: [10000000, 25000000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8],
    icon: MobileImages.vip.platinumvi,
    iconSmall: MobileImages.vip.platinum6Small,
  },
  diamond: {
    name: 'Diamond',
    level: 10,
    bonus: 150000,
    requireAmt: [25000000],
    privilege: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    icon: MobileImages.vip.diamond,
    iconSmall: MobileImages.vip.diamondSmall,
  },
};

export const VIPBONUSENUM = {
  lobby: [
    {
      webCode: null,
      name: 'LiveCasino',
      id: 1,
      subGameLobbyTypeId: 1,
      status: 1,
      brandId: 1,
      created: '2023-08-17T14:59:43',
      updated: '2023-08-25T14:20:10',
    },
    {
      webCode: null,
      name: 'Slots',
      id: 2,
      subGameLobbyTypeId: 2,
      status: 1,
      brandId: 1,
      created: '2023-08-17T14:59:47',
      updated: '2023-08-25T14:18:32',
    },
    {
      webCode: null,
      name: 'Sports',
      id: 3,
      subGameLobbyTypeId: 3,
      status: 1,
      brandId: 1,
      created: '2023-08-17T12:30:09',
      updated: '2023-09-04T11:04:38',
    },
  ],
};
