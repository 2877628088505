const IconWarning = ({ color ="#FCBC00"}) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 19.36C16.3459 19.36 20.6798 15.0261 20.6798 9.68C20.6798 4.33388 16.3459 0 10.9998 0C5.65371 0 1.31982 4.33388 1.31982 9.68C1.31982 12.6902 2.69385 15.3795 4.84894 17.1549C4.85238 17.1577 4.84932 17.1632 4.84511 17.1618C4.84252 17.1609 4.83983 17.1628 4.83983 17.1656V19.3998C4.83983 20.5924 6.09679 21.3664 7.16167 20.8296L9.3901 19.7062C9.88684 19.4558 10.4435 19.36 10.9998 19.36Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.95215 11.4647C9.95215 12.0124 10.3962 12.4565 10.9439 12.4565C11.4916 12.4565 11.9356 12.0124 11.9356 11.4647L11.9356 7.27738C11.9356 6.72966 11.4916 6.28564 10.9439 6.28564C10.3962 6.28564 9.95215 6.72966 9.95215 7.27738L9.95215 11.4647ZM10.9439 15.1014C11.4916 15.1014 11.9356 14.6573 11.9356 14.1096C11.9356 13.5619 11.4916 13.1179 10.9439 13.1179C10.3962 13.1179 9.95215 13.5619 9.95215 14.1096C9.95215 14.6573 10.3962 15.1014 10.9439 15.1014Z"
        fill="white"
      />
    </svg>
  );
};

export default IconWarning;
