import { useQuery, useMutation } from '@tanstack/react-query';
import { enqueueSnackbarMsg, formatResult } from 'common/helpers/helper';
import { useDispatch } from '../../store/StateProvider';
import { sortListAsc } from 'common/helpers/helper';
import { setBannerList, setShortcutList } from 'common/reducer-actions/bannerActions';
import { setActivePopUpList, setPopUpList, setSysAnnouncementList,setShowSysAnnouncementPopUp } from 'common/reducer-actions/popupActions';

import { StorageKeys } from 'component/mobile/home/PopUpEnum';
import { setLocalStorage, getLocalStorage } from 'common/helpers/localStorage';
import { isToday, getIndiaTimeNow } from 'common/helpers/helper';
import BannerApi from './api/bannerApi';
import { STORAGE } from 'common/constants/keys';
import { QUERY_KEYS } from 'common/constants/query-key';

/******************
 * Handle useQuery/useMutation
 ******************/

export const useGetBannerList = (type) => {
  const dispatch = useDispatch();
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.BANNER_LIST],
    queryFn: async () => {
      const res = await BannerApi.fetchBannerList(type); //1 = mobile 2 = desktop
      if (res) {
        const sortRes = sortListAsc(res);
        setBannerList(dispatch, sortRes);
        return sortRes;
      }
    },
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
};

export const useGetPopupBanner = (type = 1) => {
  const dispatch = useDispatch();
  const indiaTimeNow = getIndiaTimeNow();
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.POPUP_BANNER],
    queryFn: async () => {
      const res = await BannerApi.fetchPopupBanner(type); //1 = mobile
      if (res) {
        //handle reset popup render if its next day
        Object.values(StorageKeys).forEach((storageKey) => {
          const storedDate = getLocalStorage(storageKey);
          if (storedDate) {
            const isSameDisableDate = isToday(storedDate);
            if (!isSameDisableDate) {
              setLocalStorage(storageKey, false);
            }
          }
        });
        const filteredData = res.reduce(
          (acc, item) => {
            const isWithinDateRange =
              (item.endDate === null || new Date(item.endDate) > indiaTimeNow) &&
              (item.startDate === null || new Date(item.startDate) < indiaTimeNow);
  
            if (isWithinDateRange) {
              if (item.popupType === 1) {
                acc.sysAnnouncements.push(item);
              } else if (item.popupType === 2 && item.popupUrl) {
                acc.activePopups.push(item);
              }
            }
            return acc;
          },
          { activePopups: [], sysAnnouncements: [] },
        );
        setActivePopUpList(dispatch, filteredData.activePopups);
        setPopUpList(dispatch, filteredData.activePopups.filter((popup) => popup.homePopUpStatus === 1));
        if (filteredData.sysAnnouncements.length > 0) {
          setSysAnnouncementList(dispatch, filteredData.sysAnnouncements);
        } else {
          //switch to bonus popup when no active system announcement
          setShowSysAnnouncementPopUp(dispatch, false);
        }
        return res;
      }
    },
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
};

export const useGetBannerListVersion = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.BANNER_LIST_VERSION],
    queryFn: async () => {
      const res = await BannerApi.getBannerListVersion();
      if (res) {
        setLocalStorage(STORAGE.BannerListVersion, res);
      }
      return res;
    },
    refetchInterval: 60000,
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
};

export const useGetPopupBannerListVersion = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.POPUP_BANNER_LIST_VERSION],
    queryFn: async () => {
      const res = await BannerApi.getPopupBannerListVersion();
      if (res) {
        setLocalStorage(STORAGE.PopupBannerListVersion, res);
      }
      return res;
    },
    refetchInterval: 60000,
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
};

export const useGetEventUrl = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.EVENT_URL],
    queryFn: BannerApi.getEventUrl,
  });

  return formatResult(query, true);
};

export const useGetShortcutList = (type) => {
  const dispatch = useDispatch();
  const indiaTimeNow = getIndiaTimeNow();
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.SHORTCUT_LIST],
    queryFn: async () => {
      const res = await BannerApi.fetchShortcutList();
      if (res) {
        const filteredData = res.filter(
          (item) =>
            (item.endDate === null || new Date(item.endDate) > indiaTimeNow) &&
            (item.startDate === null || new Date(item.startDate) < indiaTimeNow),
        );
        const rows = filteredData.reduce((shortcut, item, index) => {
          const rowIndex = Math.floor(index / 4);
          if (!shortcut[rowIndex]) {
            shortcut[rowIndex] = [];
          }
          shortcut[rowIndex].push(item);
          return shortcut;
        }, []);
        setShortcutList(dispatch, rows);
        return res;
      }
    },
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
};

export const useGetShortcutListVersion = () => {
  const query = useQuery({
    queryKey: [QUERY_KEYS.BANNER.SHORTCUT_LIST_VERSION],
    queryFn: async () => {
      const res = await BannerApi.getShortcutListVersion();
      if (res) {
        setLocalStorage(STORAGE.ShortcutListVersion, res);
      }
      return res;
    },
    refetchInterval: 60000,
    meta: { preventGlobalError: true },
  });

  return formatResult(query, true);
};
