import React from 'react';
import { useNavigate } from 'react-router-dom';
import GoBack from '../goback/GoBack';
import { MobileImages } from 'common/constants/image-resource';
import IconClose2 from 'component/mobile/common/icon/IconClose2';
import styles from './CommonHeader.module.css';
import { ROUTES } from 'common/constants/routing';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CustomIcon from '../icon/CustomIcon';
import ThemeIcon from '../icon/ThemeIcon';

const CommonHeader = (props) => {
  const { handleClose, hasLogo = true, hasClose = false, hasBack = true, isModal = false } = props;
  const navigate = useNavigate();

  const closeHandler = () => {
    navigate(-1);
  };

  const gotoHome = () => {
    navigate(ROUTES.HOME);
  };
  return (
    <div>
      <div className={styles.header}>
        {hasBack && <GoBack backEvent={handleClose} arrowClass={styles.backArrow} />}
        {hasLogo && (
          <CustomIcon
            src={MobileImages.logoPrimary}
            width={90}
            height={30}
            alt="logo"
            className={styles.logo}
            onClick={() => gotoHome()}
          />
        )}
        {hasClose && (
          <div onClick={isModal ? handleClose : closeHandler} className={styles.closeContainer}>
            <ThemeIcon IconComponent={IconClose2} level="active" />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommonHeader;
