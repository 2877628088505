import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useIntl } from 'react-intl';

import RecordLine from './recordline/RecordLine';
import NoData from 'component/mobile/common/nodata/NoData';
import NumFormat from 'component/shared/numformat/NumFormat';
import { MobileImages } from 'common/constants/image-resource';

import styles from './RecordList.module.css';

const RecordList = (props) => {
  const { listData, totalAmt, totalBH, fetchNextHandler, hasMoreData, showData } = props;
  const intl = useIntl();

  return (
    <>
      {showData && listData.data.length > 0 && (
        <>
          <div className={styles.amtWrapper}>
            {(listData.type === 0 || listData.type === 1) && (
              <div className={styles.amtRow}>
                <div>{intl.formatMessage({ id: 'transaction.total' })}</div>
                <div className={styles.numFormat}>
                  <NumFormat value={totalAmt} className={styles.numFormat} />
                </div>
                <img
                  src={MobileImages.betCoin}
                  alt=""
                  width={20}
                  height={21}
                  className={styles.coin}
                />
              </div>
            )}

            {listData.type === 2 && (
              <>
                <div className={styles.amtRow}>
                  <div>{intl.formatMessage({ id: 'transaction.total_bet' })}</div>
                  <NumFormat value={totalBH.bet} />
                  <img
                    src={MobileImages.betCoin}
                    alt=""
                    width={20}
                    height={21}
                    className={styles.coin}
                  />
                </div>
                <div className={styles.winLossRow}>
                  <div>{intl.formatMessage({ id: 'transaction.total_winloss' })}</div>
                  <NumFormat value={totalBH.winLoss} />
                </div>
              </>
            )}
          </div>

          <div className={styles.recordList} id="scrollableDiv">
            <InfiniteScroll
              dataLength={listData.data.length}
              next={fetchNextHandler}
              hasMore={hasMoreData}
              scrollableTarget="scrollableDiv"
            >
              {listData &&
                listData.data.length > 0 &&
                listData.data.map((item, i) => {
                  return <RecordLine item={item} key={`record-${i}`} type={listData.type} />;
                })}
            </InfiniteScroll>
          </div>
        </>
      )}
      {showData && listData.data.length === 0 && <NoData />}
    </>
  );
};

export default RecordList;
