import PropTypes from 'prop-types';
import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
//store
import { useStore } from 'store/StateProvider';
//common
import { ROUTES } from 'common/constants/routing';
import { isUserLoggedIn } from 'common/helpers/localStorage';

/**nent
 * @param {Boolean} privateRoute only allow login user if privateRoute = true
 * @param {Boolean} protectedRoute not allow login user if protectedRoute = true
 */
const LayoutRoute = ({ privateRoute, protectedRoute, ...rest }) => {
  const [store] = useStore();
  const currentPath = window.location.pathname;

  const loggedIn = isUserLoggedIn();
  // //here code is for third party login, need user to set loginname, first/last name
  if (loggedIn && store.user?.users) {
    const whitelisted = [ROUTES.ACCOUNT.THIRD_PARTY_UPDATE_INFO, ROUTES.ACCOUNT.LOGOUT];
    if (!store.user?.users.loginName && !whitelisted.includes(currentPath)) {
      return <Navigate to={ROUTES.HOME} replace />;
    } else if (
      store.user?.users.loginName &&
      currentPath === ROUTES.ACCOUNT.THIRD_PARTY_UPDATE_INFO
    ) {
      return <Navigate to={ROUTES.HOME} replace />;
    }
  }

  const component = () => {
    if (privateRoute && !loggedIn) {
      return <Navigate to={ROUTES.ACCOUNT.LOGIN} replace />;
    } else if (protectedRoute && loggedIn) {
      return <Navigate to={ROUTES.HOME} replace />;
    }
    return <Outlet />;
  };

  return component();
};

LayoutRoute.defaultProps = {
  privateRoute: false,
  protectedRoute: false,
};

LayoutRoute.propTypes = {
  privateRoute: PropTypes.bool,
  protectedRoute: PropTypes.bool,
};

export default LayoutRoute;
