const IconSearch = ({ color = '#1883FF' }) => {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.50508 0C14.7462 0 19.0101 4.26398 19.0101 9.50509C19.0101 11.7607 18.2205 13.8351 16.9031 15.4667L17.8428 16.4064C19.0386 15.372 20.854 15.4227 21.9892 16.5578L25.109 19.6775C26.297 20.8656 26.297 22.7987 25.109 23.9868L23.9867 25.109C22.7987 26.297 20.8656 26.297 19.6775 25.1089L16.5578 21.9892C15.4226 20.854 15.372 19.0385 16.4064 17.8428L15.4667 16.9031C13.8351 18.2205 11.7607 19.0101 9.50508 19.0101C4.26398 19.0101 0 14.7462 0 9.50509C0 4.26398 4.26398 0 9.50508 0ZM17.9942 20.5529L21.1139 23.6726C21.5099 24.0687 22.1544 24.0686 22.5503 23.6726L23.6725 22.5503C24.0686 22.1543 24.0686 21.51 23.6726 21.114L20.5528 17.9943C20.1569 17.5983 19.5124 17.5982 19.1165 17.9943L17.9942 19.1165C17.5982 19.5125 17.5982 20.1568 17.9942 20.5529ZM9.50508 16.9788C13.6261 16.9788 16.9788 13.6261 16.9788 9.50509C16.9788 5.38407 13.6261 2.03136 9.50508 2.03136C5.38407 2.03136 2.03136 5.38407 2.03136 9.50509C2.03136 13.6261 5.38407 16.9788 9.50508 16.9788Z"
        fill={color}
      />
    </svg>
  );
};

export default IconSearch;
