import React, { useCallback } from 'react';
import moment from 'moment';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import NumFormat from 'component/shared/numformat/NumFormat';
import { API } from 'common/constants/api';

import styles from './RecordLine.module.css';
import IconWarning from 'component/mobile/common/icon/IconWarning';
import IconVerify from 'component/mobile/common/icon/IconVerify';
import IconFailed from 'component/mobile/common/icon/IconFailed';
import IconCopy from 'component/mobile/common/icon/IconCopy';
import ThemeIcon from 'component/mobile/common/icon/ThemeIcon';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const RecordLine = (props) => {
  const { type, item, isAdjustment = false } = props;
  //type: 0-deposit, 1-withdrawal, 2-bethistory, 3-adjustment, 4-bonus
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();

  const formatDate = useCallback(
    (item) => {
      let date = item.created;
      if (type === 4) {
        // Use processed at date for member bonus and fallback to created date if there isn't any
        if (item.processedAt !== null) {
          date = item.processedAt;
        }
      } else {
        if (!isAdjustment && item.updated !== null) {
          date = item.updated;
        }
      }
      return moment(date).format('LLL');
    },
    [isAdjustment, type],
  );

  const Status = useCallback(() => {
    let status = intl.formatMessage({ id: 'transaction.status_pending' });
    let Icon = <IconWarning />;
    switch (item.status) {
      case 1:
        status = intl.formatMessage({ id: 'transaction.status_success' });
        Icon = <IconVerify />;
        break;
      case 2:
        status = intl.formatMessage({ id: 'transaction.status_failed' });
        Icon = <IconFailed />;
        break;
      default:
        status = intl.formatMessage({ id: 'transaction.status_pending' });
        Icon = <IconWarning />;
        break;
    }

    return (
      <div className={styles.status}>
        <div>{status}</div>
        {Icon}
      </div>
    );
  }, [item.status, intl]);

  const getBonusResult = () => {
    if (item.status === 1) {
      return {
        classes: 'orange',
        label: intl.formatMessage({ id: 'transaction.bonus_status1' }),
      };
    }
    if (item.status === 2) {
      return {
        classes: 'green',
        label: intl.formatMessage({ id: 'transaction.bonus_status2' }),
      };
    }
    if (item.status === 3) {
      return {
        classes: 'red',
        label: intl.formatMessage({ id: 'transaction.bonus_status3' }),
      };
    }
  };

  const bonusResult = getBonusResult();

  const getBetResult = () => {
    if (item.status === 0) {
      return {
        classes: 'orange',
        label: intl.formatMessage({ id: 'transaction.bet_status' }),
      };
    }

    if (item.status === -1) {
      return {
        classes: 'red',
        label: intl.formatMessage({ id: 'transaction.cancel_status' }),
      };
    }

    if (item.status === -2) {
      return {
        classes: 'red',
        label: intl.formatMessage({ id: 'transaction.void_status' }),
      };
    }

    if (item.winLoss > 0) {
      return {
        classes: 'green',
        label: intl.formatMessage({ id: 'transaction.win_status' }),
      };
    }
    if (item.winLoss === 0) {
      return {
        classes: 'orange',
        label: intl.formatMessage({ id: 'transaction.draw_status' }),
      };
    }

    return {
      classes: 'red',
      label: intl.formatMessage({ id: 'transaction.lose_status' }),
    };
  };

  const betResult = getBetResult();

  const getAdjustmentResult = () => {
    if (item.status === 0) {
      return {
        classes: 'orange',
        label: intl.formatMessage({ id: 'transaction.pending' }),
      };
    }
    if (item.status === 1) {
      return {
        classes: 'green',
        label: intl.formatMessage({ id: 'transaction.approved' }),
      };
    }
    if (item.status === 2) {
      return {
        classes: 'red',
        label: intl.formatMessage({ id: 'transaction.rejected' }),
      };
    }
  };

  const adjustmentResult = getAdjustmentResult();

  const getAdjustmentType = () => {
    if (item.adjustmentType === 1) {
      return {
        label: intl.formatMessage({ id: 'transaction.add' }),
      };
    }
    if (item.adjustmentType === 2) {
      return {
        label: intl.formatMessage({ id: 'transaction.deduct' }),
      };
    }
    if (item.adjustmentType === 3) {
      return {
        label: intl.formatMessage({ id: 'transaction.bonus' }),
      };
    }
    if (item.adjustmentType === 4) {
      return {
        label: intl.formatMessage({ id: 'transaction.freechip_bonus' }),
      };
    }
    if (item.adjustmentType === 5) {
      return {
        label: intl.formatMessage({ id: 'transaction.reload_bonus' }),
      };
    }
    if (item.adjustmentType === 6) {
      return {
        label: intl.formatMessage({ id: 'transaction.member_bonus' }),
      };
    }
    if (item.adjustmentType === 7) {
      return {
        label: intl.formatMessage({ id: 'transaction.bonus_rebate' }),
      };
    }
    if (item.adjustmentType === 21) {
      return {
        label: intl.formatMessage({ id: 'transaction.bonus_expired' }),
      };
    }
  };

  const adjustmentType = getAdjustmentType();

  const handleCopy = useCallback(() => {
    return enqueueSnackbar(intl.formatMessage({ id: 'app.copy' }), {
      variant: 'success',
    });
  }, [enqueueSnackbar]);

  return (
    <>
      <div className={styles.box}>
        <div className={styles.dateRow}>
          <span>{formatDate(item)}</span>
          {(type === 0 || type === 1) && Status()}
          {type === 2 && <div className={styles.game}>{item.game}</div>}
          {type === 4 && <div className={styles.game}>{item.stringBonusType}</div>}
        </div>
        <div className={styles.downPart}>
          {(type === 1 || type === 3) && (
            <div className={styles.id}>
              {item.code}{' '}
              <div className={styles.idRight}>
                <CopyToClipboard className={styles.copyLink} text={item.code} onCopy={handleCopy}>
                  <div>
                    <ThemeIcon IconComponent={IconCopy} width={20} height={20} />
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          )}
          {(type === 0 || type === 1 || type === 3) && (
            <NumFormat
              className={styles.num}
              value={item.truncatedAmount || 0.0}
              prefix={' ' + window.CURRENCY_CODE}
            />
          )}
          {type === 0 && (
            <div className={styles.depositChannel}>
              <LazyLoadImage
                src={API.CDN + item.depositChannelIcon}
                alt="payIcon"
                className={styles.payIcon}
              />
              <div>{item.depositChannelName}</div>
            </div>
          )}
          {type === 0 && (
            <div className={styles.id}>
              {item.code}
              <div className={styles.idRight}>
                <CopyToClipboard className={styles.copyLink} text={item.code} onCopy={handleCopy}>
                  <div>
                    <ThemeIcon IconComponent={IconCopy} width={20} height={20} />
                  </div>
                </CopyToClipboard>{' '}
              </div>
            </div>
          )}
          {type === 2 && (
            <>
              <div className={styles.id}>
                {item.transactionId}{' '}
                <div className={styles.idRight}>
                  <CopyToClipboard
                    className={styles.copyLink}
                    text={item.transactionId}
                    onCopy={handleCopy}
                  >
                    <div>
                      <ThemeIcon IconComponent={IconCopy} width={20} height={20} />
                    </div>
                  </CopyToClipboard>
                </div>
              </div>
              <div className={styles.betResult}>
                <div>
                  <div className={styles.betRow}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: 'transaction.bet' })}
                    </div>
                    <NumFormat
                      className={styles.betNum}
                      value={item.truncatedBetAmount || 0.0}
                      prefix={' ' + window.CURRENCY_CODE}
                    />
                  </div>
                  <div className={styles.betRow}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: 'transaction.winLoss' })}
                    </div>
                    <NumFormat
                      className={styles.betNum}
                      value={item.truncatedWinLoss || 0.0}
                      prefix={' ' + window.CURRENCY_CODE}
                    />
                  </div>
                </div>
                <span className={`${styles.betResultLabel} ${styles[betResult.classes]}`}>
                  {betResult.label}
                </span>
              </div>
            </>
          )}
          {type === 3 && (
            <>
              <div className={styles.id}>{item.transactionId}</div>
              <div className={styles.adjustmentResult}>
                <div>{adjustmentType.label} </div>
                <span className={`${styles.betResultLabel} ${styles[adjustmentResult.classes]}`}>
                  {adjustmentResult.label}
                </span>
              </div>
            </>
          )}
          {type === 4 && (
            <>
              {item.expiryDate !== null && (
                <div className={styles.id}>过期时间: {moment(item.expiryDate).format('LLL')}</div>
              )}
              <div className={styles.bonusRow}>
                <div>
                  <div className={styles.betRow}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: 'transaction.bonus' })}
                    </div>
                    <NumFormat
                      className={styles.betNum}
                      value={item.truncatedBonusAmount || 0.0}
                      prefix={' ' + window.CURRENCY_CODE}
                    />
                  </div>
                  <div className={styles.betRow}>
                    <div className={styles.label}>
                      {intl.formatMessage({ id: 'transaction.used' })}
                    </div>
                    <NumFormat
                      className={styles.betNum}
                      value={item.truncatedBonusAmountUsed || 0.0}
                      prefix={' ' + window.CURRENCY_CODE}
                    />
                  </div>
                </div>
                <span className={`${styles.betResultLabel} ${styles[bonusResult.classes]}`}>
                  {bonusResult.label}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RecordLine;
